
import React from 'react';

const FooterSalvageData = () => {
  return (
    <div>
      <a style={{display: 'inline-block'}} href="https://www.salvagedata.com/request-help?partnerid=1064151">
        <img src="https://www.salvagedata.com/wp-content/uploads/2021/06/Salvage-Auth-Partner.png" alt="salvage data logo" />
      </a>
    </div>
  );
}

export default FooterSalvageData;